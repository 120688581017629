export function GooglePlayStoreLogo() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="google-play-store-logo"
    >
      <path
        d="M7.48727 7.63928L0.873077 14.6688C0.873077 14.6688 0.873077 14.6688 0.873077 14.675C1.07802 15.4401 1.7736 16 2.5996 16C2.92876 16 3.23928 15.9129 3.50633 15.7512L3.52496 15.7387L10.9652 11.4402L7.48727 7.63928Z"
        fill="#EA4335"
      />
      <path
        d="M14.1759 6.44484L14.1696 6.43862L10.9588 4.57239L7.33809 7.80097L10.9712 11.4401L14.1696 9.59255C14.7286 9.28773 15.1074 8.69676 15.1074 8.0187C15.1074 7.33441 14.7286 6.74966 14.1759 6.44484Z"
        fill="#FBBC04"
      />
      <path
        d="M0.873171 1.33118C0.835908 1.48048 0.811066 1.62977 0.811066 1.79151V14.2144C0.811066 14.3761 0.829697 14.5254 0.873171 14.6747L7.71094 7.82566L0.873171 1.33118Z"
        fill="#4285F4"
      />
      <path
        d="M7.53695 7.99992L10.9589 4.57227L3.52496 0.255052C3.25791 0.0933115 2.94118 0 2.5996 0C1.7736 0 1.07802 0.566091 0.873077 1.32503L7.53695 7.99992Z"
        fill="#34A853"
      />
    </svg>
  )
}
