import { transparentize } from 'polished'
import React, { ReactNode } from 'react'
import { AlertTriangle } from 'react-feather'
import styled, { css } from 'styled-components'
import { Z_INDEX } from 'theme/zIndex'

import { useIsLandingPage, useIsSwapPage } from 'hooks/useIsLandingPage'
import { ButtonText } from 'theme/components'
import { AutoColumn } from '../Column'

export const PageWrapper = styled.div`
  padding: 48px 8px 0;
  max-width: 578px;
  width: 100%;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding-top: 48px;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
  }
`

// Mostly copied from `AppBody` but it was getting too hard to maintain backwards compatibility.
export const SwapWrapperOuter = styled.main<{ isDark?: boolean; width?: string; padding?: string }>`
  position: relative;
  width: ${({ width }) => width || '578px'};
  border: ${({ isDark }) => (!isDark ? '3px solid #20242f' : 'none')};
  border-radius: ${({ isDark }) => (!isDark ? '20px' : '0')};
  z-index: ${Z_INDEX.default};
  transition: transform 250ms ease;
  background: ${({ isDark }) => (isDark ? '#fff' : '#181b25')};
  padding: ${({ padding }) => padding || '42px 32px'};
  @media (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    width: 100%;
    padding: 16px 5px;
  }
`

export const SwapWrapper = (props: React.ComponentProps<typeof SwapWrapperOuter>) => {
  const isLandingPage = useIsLandingPage()
  const isSwapPage = useIsSwapPage()
  // console.log('isSwapPage', isSwapPage)
  // console.log('isLandingPage', isLandingPage)
  return (
    <SwapWrapperOuter
      {...props}
      width={isLandingPage || isSwapPage ? '100%' : '100%'}
      padding={isLandingPage ? '20px' : isSwapPage ? '42px 32px' : '16px'}
    >
      <SwapWrapperInner>{props.children}</SwapWrapperInner>
    </SwapWrapperOuter>
  )
}

const SwapWrapperInner = styled.div`
  border-radius: 24px;
  z-index: -1;
`

export const ArrowWrapper = styled.div<{ clickable: boolean }>`
  border-radius: 90px;
  height: 36px;
  width: 36px;
  position: relative;
  margin-top: 18px;
  margin-bottom: 18px;
  margin-left: auto;
  margin-right: auto;
  background-color: ${({ theme }) => theme.accent4};
  border: 4px solid;
  border-color: ${({ theme }) => theme.accent4};

  z-index: 2;
  ${({ clickable }) =>
    clickable
      ? css`
          :hover {
            cursor: pointer;
            opacity: 0.8;
          }
        `
      : null}
`

// styles
export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }

  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`

const SwapCallbackErrorInner = styled.div`
  background-color: ${({ theme }) => transparentize(0.9, theme.critical)};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.825rem;
  width: 100%;
  padding: 3rem 1.25rem 1rem 1rem;
  margin-top: -2rem;
  color: ${({ theme }) => theme.critical};
  z-index: -1;

  p {
    padding: 0;
    margin: 0;
    font-weight: 535;
  }
`

const SwapCallbackErrorInnerAlertTriangle = styled.div`
  background-color: ${({ theme }) => transparentize(0.9, theme.critical)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 12px;
  min-width: 48px;
  height: 48px;
`

export function SwapCallbackError({ error }: { error: ReactNode }) {
  return (
    <SwapCallbackErrorInner>
      <SwapCallbackErrorInnerAlertTriangle>
        <AlertTriangle size={24} />
      </SwapCallbackErrorInnerAlertTriangle>
      <p style={{ wordBreak: 'break-word' }}>{error}</p>
    </SwapCallbackErrorInner>
  )
}

export const SwapShowAcceptChanges = styled(AutoColumn)`
  background-color: ${({ theme }) => transparentize(0.95, theme.accent1)};
  color: ${({ theme }) => theme.accent1};
  padding: 12px;
  border-radius: 12px;
`

export const SwapSection = styled.div<{ isLandingPage?: boolean; background?: string }>`
  background-color: ${({ theme, background }) => (background ? background : theme.neutral1)};
  border-radius: 16px;
  color: ${({ theme }) => theme.surface4} !important;
  font-size: 14px;
  font-weight: 500;
  //height: 120px;
  //height: 96px;
  height: ${({ isLandingPage }) => (isLandingPage ? '96px' : '120px')};
  line-height: 20px;
  padding: 8px 16px;
  position: relative;

  &:before {
    box-sizing: border-box;
    background-size: 100%;
    border-radius: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    border: 1px solid ${({ theme }) => theme.deprecated_stateOverlayHover};
  }

  //&:hover:before {
  //   border-color: ${({ theme }) => theme.deprecated_stateOverlayHover};
  //}

  &:focus-within:before {
    border-color: ${({ theme }) => theme.surface1};
  }
`

export const OutputSwapSection = styled(SwapSection)`
  // border-bottom: ${({ theme }) => `1px solid ${theme.surface1}`};
  background-color: ${({ theme, background }) => (background ? background : theme.neutral1)};
  height: ${({ isLandingPage }) => (isLandingPage ? '96px' : '120px')};
`

export const ArrowContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const SwapHeaderTabButton = styled(ButtonText)<{ $isActive: boolean }>`
  color: ${({ theme, $isActive }) => ($isActive ? theme.neutral1 : theme.neutral2)};
  //background-color: ${({ theme, $isActive }) => $isActive && theme.surface3};
  padding: 8px 16px;
  border-radius: 20px;
  gap: 4px;

  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 52px;
  @media (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    font-size: 24px;
    line-height: 27px;
  }

  &:focus {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }
`
