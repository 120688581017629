type IconProps = {
  fill?: string
  size?: string
}

export function Wallet(props: IconProps) {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4C2.34315 4 1 5.34315 1 7V18C1 19.6569 2.34315 21 4 21H20C21.6569 21 23 19.6569 23 18V7C23 5.34315 21.6569 4 20 4H4ZM3 12.2676V11C3 10.4485 3.44812 10 4.00115 10H19.9989C20.5519 10 21 10.4485 21 11V12.2676C20.7058 12.0974 20.3643 12 20 12H16C15.4477 12 14.9935 12.5284 14.7645 13.1028C14.4438 13.9072 13.789 14.8571 12 14.8571C10.29 14.8571 9.48213 13.9893 9.1936 13.2102C8.96576 12.595 8.49905 12 7.91447 12H4C3.63571 12 3.29417 12.0974 3 12.2676ZM19.9989 8C20.3498 8 20.6868 8.06029 21 8.17109V7C21 6.44772 20.5523 6 20 6H4C3.44772 6 3 6.44772 3 7V8.17109C3.31318 8.06029 3.65018 8 4.00115 8H19.9989Z"
        fill={props.fill}
      />
    </svg>
  )
}

export function HelpCircle(props: IconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM12.02 17.5C11.468 17.5 11.0149 17.052 11.0149 16.5C11.0149 15.948 11.458 15.5 12.01 15.5H12.02C12.573 15.5 13.02 15.948 13.02 16.5C13.02 17.052 12.572 17.5 12.02 17.5ZM13.603 12.5281C12.872 13.0181 12.7359 13.291 12.7109 13.363C12.6059 13.676 12.314 13.874 12 13.874C11.921 13.874 11.841 13.862 11.762 13.835C11.369 13.703 11.1581 13.278 11.2891 12.885C11.4701 12.345 11.9391 11.836 12.7671 11.281C13.7881 10.597 13.657 9.84707 13.614 9.60107C13.501 8.94707 12.95 8.38988 12.303 8.27588C11.811 8.18588 11.3301 8.31488 10.9541 8.62988C10.5761 8.94688 10.3589 9.41391 10.3589 9.90991C10.3589 10.3239 10.0229 10.6599 9.60889 10.6599C9.19489 10.6599 8.85889 10.3239 8.85889 9.90991C8.85889 8.96891 9.27099 8.08396 9.98999 7.48096C10.702 6.88496 11.639 6.63605 12.564 6.80005C13.831 7.02405 14.8701 8.07097 15.0911 9.34497C15.3111 10.607 14.782 11.7381 13.603 12.5281Z"
        fill={props.fill}
      />
    </svg>
  )
}

export function BookOpen(props: IconProps) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5 5.31967V18.3297C21.5 18.6597 21.1801 18.8898 20.8501 18.7998C18.4661 18.1208 16.073 18.1177 13.687 19.3077C13.486 19.4077 13.249 19.2717 13.249 19.0467V5.85276C13.249 5.78576 13.2701 5.71877 13.3091 5.66477C13.9321 4.80977 14.896 4.21471 16.019 4.07871C17.831 3.85871 19.5731 4.07879 21.2141 4.86179C21.3891 4.94479 21.5 5.12667 21.5 5.31967ZM8.97998 4.07968C7.16798 3.85968 5.42591 4.07976 3.78491 4.86276C3.61091 4.94576 3.5 5.12777 3.5 5.32077V18.3308C3.5 18.6608 3.8199 18.8908 4.1499 18.8008C6.5339 18.1218 8.92699 18.1187 11.313 19.3087C11.514 19.4087 11.751 19.2727 11.751 19.0477V5.85373C11.751 5.78673 11.7299 5.71974 11.6909 5.66574C11.0669 4.81074 10.104 4.21568 8.97998 4.07968Z"
        fill={props.fill}
      />
    </svg>
  )
}

export function ChatBubbles(props: IconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.8789 20.394C21.1189 20.594 20.9789 20.994 20.6689 20.994C19.6389 21.034 18.0591 20.894 17.0891 19.864C16.2991 20.144 15.419 20.2841 14.499 20.2841C12.481 20.2841 10.686 19.607 9.49902 18.368C9.31002 18.17 9.35802 17.785 9.79102 17.819C10.024 17.835 10.259 17.844 10.499 17.844C15.119 17.844 18.614 15.143 19.353 11.235C19.403 10.973 19.7291 10.886 19.8931 11.097C20.5931 12.003 20.998 13.1481 20.998 14.5031C20.998 16.0331 20.4679 17.314 19.5879 18.264C19.6989 18.964 20.0889 19.754 20.8789 20.394ZM17.998 9.67102C17.998 9.62402 17.992 9.58103 17.991 9.53503C17.917 5.44903 14.594 3 10.499 3C6.35802 3 3 5.50202 3 9.67102C3 11.439 3.608 12.915 4.625 14.015C4.5 14.816 4.05009 15.733 3.14209 16.467C2.86709 16.7 3.02503 17.159 3.38403 17.167C4.56703 17.209 6.39203 17.05 7.50903 15.858C7.73703 15.937 7.97094 16.006 8.21094 16.066C8.93194 16.247 9.69998 16.3409 10.501 16.3409C14.64 16.3419 17.998 13.84 17.998 9.67102Z"
        fill={props.fill}
      />
    </svg>
  )
}

export function Computer(props: IconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.3164 14.7471C21.3164 16.7471 20.3164 17.7471 18.3164 17.7471H14.3164V20.9971H16.8164C17.2264 20.9971 17.5664 21.3371 17.5664 21.7471C17.5664 22.1571 17.2264 22.4971 16.8164 22.4971H7.81641C7.40641 22.4971 7.06641 22.1571 7.06641 21.7471C7.06641 21.3371 7.40641 20.9971 7.81641 20.9971H10.3164V17.7471H6.31641C4.31641 17.7471 3.31641 16.7471 3.31641 14.7471C3.31641 14.6091 3.42841 14.4971 3.56641 14.4971H21.0664C21.2044 14.4971 21.3164 14.6091 21.3164 14.7471ZM18.3164 3.74707H6.31641C4.31641 3.74707 3.31641 4.74707 3.31641 6.74707V12.7471C3.31641 12.8851 3.42841 12.9971 3.56641 12.9971H21.0664C21.2044 12.9971 21.3164 12.8851 21.3164 12.7471V6.74707C21.3164 4.74707 20.3164 3.74707 18.3164 3.74707Z"
        fill={props.fill}
      />
    </svg>
  )
}

export function ArrowRight(props: IconProps) {
  return (
    <svg
      style={{ flex: 'none' }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12.1143C4 11.562 4.44771 11.1143 5 11.1143L16.5858 11.1143L11.2929 5.82136C10.9024 5.43084 10.9024 4.79767 11.2929 4.40715C11.6834 4.01663 12.3166 4.01663 12.7071 4.40715L19.7071 11.4072C20.0976 11.7977 20.0976 12.4308 19.7071 12.8214L12.7071 19.8214C12.3166 20.2119 11.6834 20.2119 11.2929 19.8214C10.9024 19.4308 10.9024 18.7977 11.2929 18.4072L16.5858 13.1143L5 13.1143C4.44771 13.1143 4 12.6665 4 12.1143Z"
        fill={props.fill}
      />
    </svg>
  )
}

export function Twitter(props: IconProps) {
  return (
    <svg width={props.size ?? '40'} height={props.size ?? '40'} viewBox="0 0 53 53" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#a)">
        <circle cx={35.499} cy={22.03} r={16} fill="#fff" />
      </g>
      <path
        fill="#1324EE"
        d="M35.5 5.23c-9.28 0-16.8 7.521-16.8 16.8 0 9.278 7.52 16.8 16.8 16.8 9.278 0 16.8-7.522 16.8-16.8 0-9.279-7.522-16.8-16.8-16.8Zm6.833 13.762c.007.143.009.287.009.427 0 4.375-3.327 9.416-9.413 9.416a9.337 9.337 0 0 1-5.072-1.487c.257.032.521.044.79.044 1.55 0 2.976-.527 4.108-1.416a3.316 3.316 0 0 1-3.09-2.296 3.325 3.325 0 0 0 1.492-.058 3.311 3.311 0 0 1-2.654-3.244v-.04a3.32 3.32 0 0 0 1.5.414 3.307 3.307 0 0 1-1.024-4.42 9.409 9.409 0 0 0 6.82 3.46 3.31 3.31 0 0 1 5.638-3.02 6.645 6.645 0 0 0 2.1-.802 3.325 3.325 0 0 1-1.454 1.832 6.647 6.647 0 0 0 1.9-.523 6.709 6.709 0 0 1-1.65 1.713Z"
      />
    </svg>
  )
}

export function Instagram(props: IconProps) {
  return (
    <svg width={props.size ?? '40'} height={props.size ?? '40'} viewBox="0 0 53 53" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#a)">
        <circle cx={35.1} cy={22.03} r={16} fill="#fff" />
      </g>
      <path
        fill="#1324EE"
        d="M40.35 22.03a5.25 5.25 0 1 1-10.5 0c0-.3.031-.592.086-.875H28.1v6.994c0 .487.394.88.88.88h12.241a.88.88 0 0 0 .879-.88v-6.994h-1.836c.054.283.086.575.086.875Zm-5.25 3.5a3.5 3.5 0 1 0-.001-7 3.5 3.5 0 0 0 0 7Zm4.2-7.175h2.098a.527.527 0 0 0 .527-.525v-2.098a.527.527 0 0 0-.527-.527H39.3a.527.527 0 0 0-.527.527v2.098a.53.53 0 0 0 .527.525ZM35.1 5.23a16.8 16.8 0 1 0 0 33.6 16.8 16.8 0 0 0 0-33.6Zm8.75 23.605a1.95 1.95 0 0 1-1.944 1.945H28.294a1.95 1.95 0 0 1-1.944-1.945V15.224a1.95 1.95 0 0 1 1.944-1.944h13.612a1.95 1.95 0 0 1 1.944 1.944v13.611Z"
      />
      <defs>
        <filter
          id="a"
          width={69.6}
          height={69.6}
          x={0.3}
          y={0.23}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="
            0 0 0 0 0
            0 0 0 0 0
            0 0 0 0 0
            0 0 0 0 127
            0"
          />
          <feOffset dy={13} />
          <feGaussianBlur stdDeviation={9} />
          <feColorMatrix
            values="
            0 0 0 0 0.0658854
            0 0 0 0 0.0730469
            0 0 0 0 0.1375
            0 0 0 0 0.08
            0"
          />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1521_18575" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1521_18575" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export function Facebook(props: IconProps) {
  return (
    <svg width={props.size ?? '40'} height={props.size ?? '40'} viewBox="0 0 53 53" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#a)">
        <circle cx={34.9} cy={22.03} r={16} fill="#fff" />
      </g>
      <path
        fill="#1324EE"
        d="M34.9 5.23c-9.279 0-16.8 7.521-16.8 16.8 0 9.278 7.521 16.8 16.8 16.8 9.278 0 16.8-7.522 16.8-16.8 0-9.279-7.522-16.8-16.8-16.8Zm3.98 11.61h-2.526c-.3 0-.632.393-.632.916v1.824h3.159l-.478 2.6h-2.68v7.807h-2.981V22.18h-2.704v-2.6h2.704v-1.53c0-2.194 1.522-3.978 3.612-3.978h2.525v2.767Z"
      />
      <defs>
        <filter
          id="a"
          width={69.6}
          height={69.6}
          x={0.1}
          y={0.23}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="
            0 0 0 0 0
            0 0 0 0 0
            0 0 0 0 0
            0 0 0 0 127
            0"
          />

          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1521_18569" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1521_18569" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export function Bars(props: IconProps) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7148 21.0011H11.7148C10.7148 21.0011 10.2148 20.5011 10.2148 19.5011V4.50108C10.2148 3.50108 10.7148 3.00108 11.7148 3.00108H12.7148C13.7148 3.00108 14.2148 3.50108 14.2148 4.50108V19.5011C14.2148 20.5011 13.7148 21.0011 12.7148 21.0011ZM21.2148 19.5011V9.50108C21.2148 8.50108 20.7148 8.00108 19.7148 8.00108H18.7148C17.7148 8.00108 17.2148 8.50108 17.2148 9.50108V19.5011C17.2148 20.5011 17.7148 21.0011 18.7148 21.0011H19.7148C20.7148 21.0011 21.2148 20.5011 21.2148 19.5011ZM7.21484 19.5011V13.5011C7.21484 12.5011 6.71484 12.0011 5.71484 12.0011H4.71484C3.71484 12.0011 3.21484 12.5011 3.21484 13.5011V19.5011C3.21484 20.5011 3.71484 21.0011 4.71484 21.0011H5.71484C6.71484 21.0011 7.21484 20.5011 7.21484 19.5011Z"
        fill={props.fill}
      />
    </svg>
  )
}

export function CodeBrackets(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M7.00001 17C6.74401 17 6.48801 16.902 6.29301 16.707L2.29301 12.707C1.90201 12.317 1.90201 11.683 2.29301 11.293L6.29301 7.29299C6.68401 6.90199 7.31601 6.90199 7.70701 7.29299C8.09801 7.68299 8.09801 8.31699 7.70701 8.70699L4.41401 12L7.70701 15.293C8.09801 15.683 8.09801 16.317 7.70701 16.707C7.51201 16.902 7.25601 17 7.00001 17ZM17.707 16.707L21.707 12.707C22.098 12.317 22.098 11.683 21.707 11.293L17.707 7.29299C17.316 6.90199 16.684 6.90199 16.293 7.29299C15.902 7.68299 15.902 8.31699 16.293 8.70699L19.586 12L16.293 15.293C15.902 15.683 15.902 16.317 16.293 16.707C16.488 16.902 16.744 17 17 17C17.256 17 17.512 16.902 17.707 16.707ZM9.937 20.351L15.937 4.35101C16.131 3.83401 15.869 3.25699 15.352 3.06399C14.834 2.86999 14.259 3.13198 14.064 3.64898L8.064 19.649C7.87 20.166 8.13201 20.743 8.64901 20.936C8.76501 20.98 8.88301 21 9.00001 21C9.40501 21 9.786 20.752 9.937 20.351Z"
        fill={props.fill}
      />
    </svg>
  )
}
